import React from 'react';
import './navbar.css';
import {Link} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import Logo from '../navbar/logo-alt.png';

function Navbar(){

    const dispatch = useDispatch();
    const logado = useSelector(state=> state.usuarioLogado);
    const adm = useSelector(state=> state.usuarioEmail);

    return(
        <nav className="navbar navbar-expand-lg ">
            <div className="container-fluid">
                <span className="navbar-brand text-white font-weight-bold" href=""><Link to={"/"}><img src={Logo}></img></Link></span>
                
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <i className="fas fa-bars text-white"></i>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav">
                        <li className="nav-item"><Link className="nav-link"  to="/">HOME</Link></li>
                       {
                           
                        logado > 0 && adm === "brenovilefort2a@gmail.com" ?
                        <>
                            
                            <li className="nav-item"><Link className="nav-link"  to="/eventocadastro">PUBLICAR ARQUIVO</Link></li>
                            <li className="nav-item"><Link className="nav-link"  to="/arquivos/1/nova-ordem-mundial">NOVA ORDEM MUNDIAL</Link></li>
                            <li className="nav-item"><Link className="nav-link"  to="/arquivos/2/historia-escondida">HISTÓRIA ESCONDIDA</Link></li>
                            <li className="nav-item"><Link className="nav-link"  to="/arquivos/3/pandemia">PANDEMIA</Link></li>
                            <li className="nav-item"><Link className="nav-link"  to="/arquivos/4/terra-plana">TERRA PLANA</Link></li>
                            <li className="nav-item"><Link className="nav-link"  to="" ><a onClick={()=>dispatch({type: 'LOG_OUT'})} >SAIR</a></Link></li>
                        
                        </>
                        : logado > 0  ?
                        <>

                            
                            <li className="nav-item"><Link className="nav-link"  to="/arquivos/1/nova-ordem-mundial">NOVA ORDEM MUNDIAL</Link></li>
                            <li className="nav-item"><Link className="nav-link"  to="/arquivos/2/historia-escondida">HISTÓRIA ESCONDIDA</Link></li>
                            <li className="nav-item"><Link className="nav-link"  to="/arquivos/3/pandemia">PANDEMIA</Link></li>
                            <li className="nav-item"><Link className="nav-link"  to="/arquivos/4/terra-plana">TERRA PLANA</Link></li>
                            <li className="nav-item"><Link className="nav-link"  to="" ><a onClick={()=>dispatch({type: 'LOG_OUT'})} >SAIR</a></Link></li>

                        </>
                       : 
                        <>
                            <li className="nav-item"><Link className="nav-link"  to="/arquivos/1/nova-ordem-mundial">NOVA ORDEM MUNDIAL</Link></li>
                            <li className="nav-item"><Link className="nav-link"  to="/arquivos/2/historia-escondida">HISTÓRIA ESCONDIDA</Link></li>
                            <li className="nav-item"><Link className="nav-link"  to="/arquivos/3/pandemia">PANDEMIA</Link></li>
                            <li className="nav-item"><Link className="nav-link"  to="/arquivos/4/terra-plana">TERRA PLANA</Link></li>
                            <li className="nav-item"><Link className="nav-link"  to="/login">LOGIN</Link></li>
   
                       </>
                        }
                        

                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Navbar;