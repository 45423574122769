import React, {useState} from 'react';
import './usuario-recuperar-senha.css';
import Navbar from '../../components/navbar/';

import firebase from '../../config/firebase';
import 'firebase/auth';

function UsuarioRecuperarSenha(){
    
    const [email, setEmail] = useState();
    const [msg, setMsg] = useState();

    function recuperaSenha(){
        firebase.auth().sendPasswordResetEmail(email).then(resultado => {
            setMsg('Enviamos um link no seu email para você redefinir sua senha!');
        }).catch(erro=>{
            setMsg('Verifique se o email informado está correto');
        })
    }
    return(
        <>
        <Navbar/>
      
            <form className="text-center form-login mx-auto mt-5 d-grid">

            <h3 className="mb-3 font-wheight-bold">Recuperar Senha</h3>

            <input onChange={(e) => setEmail(e.target.value)} type="email" className="form-control my-2" placeholder="Email"/>
            
            <div className="msg my-2 text-center">
                <span>{msg}</span>
            </div>
            <buttton onClick={recuperaSenha} type="button" className="btn btn-lg btn-block btn-enviar">Recuperar Senha</buttton>

            </form>
       
      
        

        </>
    )
}
export default UsuarioRecuperarSenha;