import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import firebase from '../../config/firebase';
import { useSelector } from 'react-redux';
import Navbar from '../../components/navbar/';


function Play(props) {


	const [video, setVideo] = useState({});
	const [urlVideo, setUrlVideo] = useState({});
	const usuarioEdit = useSelector(state => state.usuarioEmail);
	const [excluido, setExcluido] = useState(0);

	function remover(){
		
		firebase.firestore().collection('arquivos').doc(props.match.params.id).delete().then(()=>{
			setExcluido(1);
		})
	}



	useEffect(() => {
		firebase.firestore().collection('arquivos').doc(props.match.params.id).get().then(async (resultado) => {
			setVideo(resultado.data())
			firebase.firestore().collection('arquivos').doc(props.match.params.id).update('visualizacoes', resultado.data().visualizacoes + 1)
			await firebase.storage().ref(`videos/${resultado.data().imagem}`).getDownloadURL().then(url => setUrlVideo(url));
		});


	}, [])
	return (
		<>
			<Navbar />
			{excluido ? <Redirect to='/'/> : null}
			<div className='container mt-5'>
				<article className="article article-post">
					<div className="article-share">
						<a href="#" className="facebook"><i className="fa fa-facebook"></i></a>
						<a href="#" className="twitter"><i className="fa fa-twitter"></i></a>
						<a href="#" className="google"><i className="fa fa-google-plus"></i></a>
					</div>
					<div className="article-main-img">

						<div className="embed-responsive embed-responsive-16by9">
							<iframe  width="100%" height="100%" 
							
							src={urlVideo} allow="autoplay" allowFullScreen="true">

							</iframe>
						</div>



					</div>
					<div className="article-body">
						<ul className="article-info">
							<li className="article-category"><Link to='/'>Home</Link></li>
							<li className="article-type"><i className="fa fa-file-text"></i></li>
							
							{
								
								usuarioEdit == 'brenovilefort2a@gmail.com' ?
									<li className='article-type'><Link to={`/editararquivo/${props.match.params.id}`} ><i className="fa fa-pen-square text-white"></i></Link></li>
									
									: ''
							}
								
								{
								
								usuarioEdit == 'brenovilefort2a@gmail.com' ?
									
									<button onClick={remover} className="btn btn-block btn-lg btn-remove" type="button" >Remover</button>
									: ''
							}

						</ul>
						<h1 className="article-title">{video.titulo}</h1>
						<ul className="article-meta">
							<li><i className="fa fa-clock-o"></i> {video.data}</li>
							<li><i className="fa fa-eye"></i> {video.visualizacoes}</li>
						</ul>
						<p>{video.detalhes}</p>
					</div>

				</article>


			</div>


		</>
	)
}
export default Play;