import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import firebase from '../../config/firebase';


function ArquivosSlider({ Key, titulo, arquivo, visualizacoes, data, hora }) {
    
        
    const [urlImagem, setUrlImagem] = useState();
    


    useEffect(() => {
        firebase.storage().ref(`videos/${arquivo}`).getDownloadURL().then(url => setUrlImagem(url));
    }, [urlImagem]);

    return (
        
        <>
            <div id="owl-carousel-1" className="owl-carousel owl-theme center-owl-nav col-md-6">


                <article  className="article thumb-article espaco">
                    <div className="article-img">
                        <Link to={"/play/" + Key}>
                            <img src={urlImagem} alt="imagem do slider" />

                        </Link>
                    </div>
                    <div className="article-body">
                        <ul className="article-info">
                            <li className="article-category"><Link to={"/play/" + Key}>Novo</Link></li>
                            <li className="article-type"><i className="fa fa-video"></i></li>
                        </ul>
                        
                        <h2 className="article-title"><Link to={"/play/" + Key}>{titulo}</Link></h2>
                        <ul className="article-meta">
                            <li><i className="fa fa-clock-o"></i> {data}</li>
                            <li><i className="fa fa-clock-o"></i> {hora}</li>
                            <li><i className="fa fa-eye"></i> {visualizacoes}</li>
                        </ul>
                    </div>
                </article>
            </div>
        </>
    )
    
}

export default ArquivosSlider;