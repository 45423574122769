import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { store, persistor } from '../src/store';
import { Provider } from 'react-redux';
/*PAGINAS*/
import Login from './view/login/';
import NovoUsuario from './view/usuario-novo/';
import Home from './view/home/';
import UsuarioRecuperarSenha from './view/usuario-recuperar-senha/';
import EventoCadastro from './view/evento-cadastro/';
import Play from './view/play/';
import { PersistGate } from 'redux-persist/integration/react';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Route exact path='/' component={Home} />
          <Route path='/arquivos/1/:parametro' component={Home} />
          <Route path='/arquivos/2/:parametro' component={Home} />
          <Route path='/arquivos/3/:parametro' component={Home} />
          <Route path='/arquivos/4/:parametro' component={Home} />
          <Route exact path='/novousuario' component={NovoUsuario} />
          <Route exact path='/login' component={Login} />
          <Route exact path='/usuariorecuperarSenha' component={UsuarioRecuperarSenha} />
          <Route exact path='/eventocadastro' component={EventoCadastro} />
          <Route path='/play/:id' component={Play} />
          <Route path='/editararquivo/:id' component={EventoCadastro} />
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
