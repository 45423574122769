import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import firebase from '../../config/firebase';


function ArquivosCard({ Key, titulo, detalhes, arquivo, visualizacoes, data, hora }) {
	const [urlImagem, setUrlImagem] = useState([]);
	
	

	useEffect(() => {
		firebase.storage().ref(`videos/${arquivo}`).getDownloadURL().then(url => setUrlImagem(url));
		
		console.log('carregou algo');
	},[urlImagem, arquivo]);
	return (
		<div className="col-md-3 col-sm-6">
			<article className="article">
				<div className="article-img">
					<Link to={"/play/" + Key}>
						<img src={urlImagem} alt="" />
					</Link>
					<ul className="article-info">
						<li className="article-type"><i className="fa fa-play"></i></li>
					</ul>
				</div>
				<div className="article-body">
					<h3 className="article-title"><Link to={"/play/" + Key}>{titulo}</Link></h3>
					<ul className="article-meta">
						<li><i className="fa fa-calendar" aria-hidden="true"></i> {data}</li>
						<li><i className="fa fa-clock-o"></i> {hora}</li>
						<li><i className="fa fa-eye"></i> {visualizacoes}</li>
					</ul>
					<p>{detalhes}</p>
				</div>
			</article>
		</div>
	)
}

export default ArquivosCard;