import firebase from 'firebase';


const firebaseConfig = {
  apiKey: "AIzaSyAR504GHctkAprxQUjakrIkRXWsWdRnE5I",
  authDomain: "eventos-bf579.firebaseapp.com",
  projectId: "eventos-bf579",
  storageBucket: "eventos-bf579.appspot.com",
  messagingSenderId: "609908122181",
  appId: "1:609908122181:web:dd1aff06fbb3e1162db92c"
};
// const firebaseConfig = {
//   apiKey: "AIzaSyDj_FKGxMggdEdELNYDEgkoI8H_DgZKrQI",
//   authDomain: "redpill1845.firebaseapp.com",
//   projectId: "redpill1845",
//   storageBucket: "redpill1845.appspot.com",
//   messagingSenderId: "465752350099",
//   appId: "1:465752350099:web:905f524df17d20e6199c66"
// };



// Initialize Firebase
export default firebase.initializeApp(firebaseConfig);