import React, {useState} from 'react';
import firebase from '../../config/firebase';
import 'firebase/auth';
import Navbar from '../../components/navbar/';

import './usuario-novo.css';

function NovoUsuario(){

    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const [msgTipo, setMsgTipo] = useState('');
    const [msg, setMsg] = useState('');
    const [carregando, setCarregando] = useState('');

    function cadastrar(){
        setMsgTipo(null);
        setCarregando(1);

        if(!email || !senha){
            setCarregando(0);
            setMsgTipo('erro')
            setMsg('Você precisa informoar email e senha para fazer o cadastro!')
            return;
        }

        firebase.auth().createUserWithEmailAndPassword(email,senha).then(resultado=>{
            setCarregando(0);
            setMsgTipo('sucesso')
        }).catch(erro=> {
            setCarregando(0);
            setMsgTipo('erro')
            switch(erro.message)
            {
                case 'Password should be at least 6 characters':
                    setMsg('A senha deve ter pelo menos 6 caracteres');
                    break;
                case 'The email address is badly formatted.':
                    setMsg('O formato de seu email é inválido!');
                    break;
                case 'The email address is already in use by another account.':
                    setMsg('Este email já está sendo utilizado por outro usuário!');
                    break;
                default:
                    setMsg('Não foi possível cadastrar. Tente novamente mais tarde!');
                    break;            

            }
        })
    }

    return(
        <>
        <Navbar/>
        <div className= "form-cadastro">
            <form className="text-center form-login mx-auto mt-5 d-grid gap-2"
            >
                <h1 className="h3 mb-3 text-black font-weight-bold">Cadastro</h1>

                <input onChange={ (e) => setEmail(e.target.value) } type="email" className="form-control my-2" placeholder="Email"/>
                <input onChange={ (e) => setSenha(e.target.value) } type="password" className="form-control my-2" placeholder="Senha"/>


                {
                    carregando ? <div className="d-flex justify-content-center"><div class = "spinner-border text-danger" role= "status"><span className="visually-hidden">Loading...</span></div></div>
                    :<button onClick={cadastrar} type="button" className="btn btn-lg btn-block mt-3 mb-5 btn-cadastro">Cadastro</button>
                }
                
                
                <div className="msg-login text-center text-black mt-5">
                
                {msgTipo === 'sucesso' && <span><strong>Wow!</strong>Usuário cadastrado com {msgTipo}!  &#128526;</span>}
                {msgTipo === 'erro' && <span><strong>Ops!</strong> {msg}  &#9888;</span>}
                       
                </div>
            </form>
        </div>
        </>
    )
}

export default NovoUsuario;