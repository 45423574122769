import React, { useState, useEffect } from 'react';
import Logo from '../navbar/logo-alt.png';
import { Link } from 'react-router-dom';
import firebase from '../../config/firebase';

function Footer({ Key, titulo, detalhes, arquivo, visualizacoes, data, hora }) {

	const [urlImagem, setUrlImagem] = useState([]);


	useEffect(() => {
		firebase.storage().ref(`videos/${arquivo}`).getDownloadURL().then(url => setUrlImagem(url));

		console.log('carregou algo');
	}, []);



	return (






		<>
			<div id="top-footer" className="section">

				<div className="container">

					<div className="row">

						<div className="col-md-3">

							<div className="footer-widget about-widget">
								<div className="footer-logo">
									<a href="#" className="logo"><img src={Logo} alt="" /></a>
									<p></p>
								</div>
							</div>



							<div className="footer-widget social-widget">
								<div className="widget-title">
									<h3 className="title">Siga-nos</h3>
								</div>
								<ul>
									<li><a href="#" className="facebook"><i className="fa-brands fa-facebook-f"></i></a></li>
									<li><a href="#" className="twitter"><i className="fa-brands fa-twitter"></i></a></li>
									<li><a href="#" className="instagram"><i className="fa-brands fa-instagram"></i></a></li>

								</ul>
							</div>






						</div>
						<div className="col-md-3">
						<div className="footer-widget subscribe-widget">
							<div className="widget-title">
								<h2 className="title">Mande-nos dicas de vídeos e imagens!</h2>
							</div>
							<form action="contact.php" method="post">
								<input className="input" name= "email" type="email" placeholder="Seu email aqui" />
								<textarea className="input form-control mt-2"  rows="3" name= "message" type="text" placeholder="Deixe sua mensagem" />
								<button type="submit" className="input-btn">Enviar</button>
							</form>
						</div>
						</div>

						<div className="col-md-3">

							<div className="footer-widget">
								<div className="widget-title">
									<h2 className="title">ultimo vídeo adicionado</h2>
								</div>


								<article className="article widget-article">
									<div className="article-img">
										<Link to={"/play/" + Key}>
											<img src={urlImagem} alt="" />
										</Link>
									</div>
									<div className="article-body">
										<h4 className="article-title"><Link to={"/play/" + Key}>{titulo}</Link></h4>
										<ul className="article-meta">
											<li><i className="fa-brands fa-clock-o"></i> {data}</li>
											<li><i className="fa-brands fa-eyes"></i> {visualizacoes}</li>
										</ul>
									</div>
								</article>

							</div>

						</div>



						<div className="col-md-3">

							<div className="footer-widget galery-widget">
								<div className="widget-title">
									<h2 className="title">Flickr Photos</h2>
								</div>
								<ul>
									<li><a href="#"><img src="./img/img-widget-3.jpg" alt="" /></a></li>
									<li><a href="#"><img src="./img/img-widget-4.jpg" alt="" /></a></li>
									<li><a href="#"><img src="./img/img-widget-5.jpg" alt="" /></a></li>
									<li><a href="#"><img src="./img/img-widget-6.jpg" alt="" /></a></li>

								</ul>
							</div>



							<div className="footer-widget tweets-widget">
								<div className="widget-title">
									<h2 className="title">Recent Tweets</h2>
								</div>
								<ul>
									<li className="tweet">
										<i className="fa fa-twitter"></i>
										<div className="tweet-body">
											<p><a href="#"></a> <a href="#"></a></p>
										</div>
									</li>
								</ul>
							</div>

						</div>

					</div>

				</div>

			</div>



			<div id="bottom-footer" className="section">

				<div className="container">

					<div className="row">





						<div className="col-md-12 col-md-pull-12">
							<div className="footer-copyright">
								<span>
									Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved Redpill Files
								</span>
							</div>
						</div>

					</div>

				</div>

			</div>

		</>

	)
}
export default Footer;